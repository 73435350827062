.testimonial {
  flex-shrink: 0;
  background: #f2fff6;
  padding: 65px 120px;
}
@media (orientation: portrait) {

  .testimonial {
    flex-shrink: 0;
    background: #f2fff6;
    padding: 0px 0 35px 0;
  }
  .testimonial .testimonial-heading p {
    /* font-size: 40px; */
  }
}
