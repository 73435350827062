.logo_menu_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu_toggler {
  display: block;
}
.menu_toggler button svg {
  width: 28px;
  height: 28px;
}

.navbar .logo_wrapper {
  padding-right: 3rem;
}

.menu_large > ul li {
  display: inline-block;
}

.menu_large .btn_menu {
  padding: 12px 20px;
  text-transform: uppercase;
  border: 1px solid #fff;
}
.dash_border {
  padding: 0 1.6rem;
  border: 1px dashed var(--white);
  text-transform: uppercase;
  color: var(--white);
}
@media (orientation: portrait) {
  .navbar .logo_wrapper {
    padding: 0 1.8rem 0 1.2rem;
  }
  .menu_toggler {
    display: block;
  }

  .logo_menu_wrapper-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 32px;
  }
  .logo_menu_wrapper-mobile img {
    width: 66px;
    height: 67px;
    flex-shrink: 0;
  }
  .logo_menu_wrapper-mobile svg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
  .menu_large {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    height: 0;
    overflow: hidden;
  }

  .menu_large.show {
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
  }

  .menu_large > ul li {
    display: block;
  }
  .menu_large > ul {
    text-align: left;
    margin-top: 2.5rem;
    padding-left: 32px;
  }

  .menu_large > ul li {
    margin-bottom: 2.75rem;
    color: #1e1e1e;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.09px;
  }

  .menu_large .btn_menu {
    padding: 1.5rem 3rem;
  }
}
