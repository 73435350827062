.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index:101;
  /* background-color: #084210c4; */
  padding: 29px 117px;
}
.navbar_menu{
 display: none;
}
.flex_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .flex_navbar .nav_item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2.75rem; 
}
.navbar .flex_navbar p {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.09px; 
}

@media (orientation: portrait) {
  .navbar {
    padding: 80px 30px;
    /* background-color: #FFF; */
  }
  .flex_navbar{
      display: none;
  }
  .navbar_menu{
    display: block;
   }
}
