@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Sora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  font-family: 'Raleway';
  font-weight     : 400;
  font-size       : 1rem;
  line-height     : 1.375rem;
  color           : #043D16;
  background-color: #FFFFFF;
} 

.container {
  max-width: 97.5rem;
  margin: 0 auto;
  /* padding: 0 24px; */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.common_navigation .swiper-button-next,
.common_navigation .swiper-button-prev {
    width        : 5.25rem;
    height       : 5.25rem;
    border       : 1px solid #ACACAC;
    border-radius: 50%;
}

.common_navigation .swiper-button-next:hover,
.common_navigation .swiper-button-prev:hover {
    border: 1px solid #043D16;
}

.common_navigation .swiper-button-next:after {
    content            : '';
    width              : 18.9px;
    height             : 18.9px;
    background         : url('../src/assets/icons/arrow-right.svg') no-repeat 100%;
    background-position: center;
}

.common_navigation .swiper-button-prev:after {
    content            : '';
    width              : 18.9px;
    height             : 18.9px;
    background         : url('../src/assets/icons/arrow-left.svg') no-repeat 100%;
    background-position: center;
}

/* .App-header {
  background-color: #15162B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(.625rem + 2vmin);
  color: white;
} */
/* >>== responsive min width start ==<< */
@media (orientation: portrait) {
  .common_navigation  .swiper-button-prev{
    top: 88%;
    left: 33%;
  }
  .common_navigation  .swiper-button-next{
    top: 88%;
    left: 55%;
  }
  .common_navigation .swiper-button-next, .common_navigation .swiper-button-prev{
    width: 3rem;
    height: 3rem;
  }
  .container {
    padding:  0 20px;
  }
}


