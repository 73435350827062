.testimonial .testimonial-heading p {
  color: #043d16;
  text-align: center;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.testimonial .testimonial-heading span {
  font-weight: 300;
}
#testimonial-sub-title {
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-style: s;
  font-weight: 300;
  line-height: 21px;
  width: 750px;
  margin: 0 auto;
  text-transform: capitalize;
}
.user-img {
  width: 100%;
  margin: 0;
  flex-shrink: 0;
}
.user-img .use_imgm {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  margin: 0 24px;
  border-radius: 50%;
  overflow: hidden;
}
.user-img .img-tb {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}
.user-img .designation {
  margin-top: 42px;
}
.user-img .designation #designation-name {
  color: #1e1e1e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.user-img .designation #designation-title {
  color: #1e1e1e;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.user-description p {
  width: 100%;
  flex-shrink: 0;
  margin: 0 auto;
}
.user-description p {
  color: #1e1e1e;
  text-align: center;
  font-family: Karla;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px; /* 130% */
  letter-spacing: 0.2px;
  padding-top: 5rem;
  margin-bottom: 3rem;
}
.user-description {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}
.user-description img {
  position: absolute;
  left: 0;
  top: 0;
  width: 85px;
  height: 85px;
  margin: 0 auto;
}
.description-img {
  display: none;
}

.horizontal-line {
  width: 75px;
  height: 2px;
  background-color: #fbd601;
  margin: 0 auto;
  margin-top: 22px;
}

@media (orientation: portrait) {
  .testimonial .testimonial-heading p {
    font-size: 24px;
  }
  #testimonial-sub-title {
    font-size: 12px;
    width: 100%;
  }
  .user-img .img-tb {
    display: none;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  .user-img .use_imgm {
    width: 85px;
    height: 85px;
  }
  .user-img .designation #designation-name {
    color: #1e1e1e;
    text-align: center;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .user-img .designation #designation-title {
    font-size: 0.75rem;
  }
  .user-description p {
    width: 100%;
  }
  .user-description p {
    font-size: 12px;
    padding: 0 10px;
  }
  .user-description-img {
    display: none;
  }
  .description-img {
    display: block;
  }
  .description-img img {
    width: 45px;
    height: 45px;
  }

  .testimonial .testimonial-heading p {
    font-size: 24px;
    padding: 0px 20px;
  }
}
