.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 118px 0; 
}

.features .features-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.features .features-items img {
  width: 75px;
  height: 75px;
}
.features .features-items p {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}


@media (orientation: portrait) {
  /* .features {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 2rem;
    row-gap:  4rem;
  } */

  .features {
    grid-template-columns: repeat(2, 1fr); 
    row-gap: 36px;
  }


  .features .features-items:nth-child(3) {
    grid-column: 1 / span 2; 
    grid-row: 2; 
  }


  .features {
  padding: 50px 0; 
}
.features .features-items img {
  width: 45px;
  height: 45px;
}
.features .features-items p {
  font-size: 16px;
}

}

