.about-section {
  width: 100vw;
  padding: 102px 262px;
  text-align: left;
  margin: 0 auto;
}
.about-section .about-section-item {
  position: relative;
  height: 500px;
  /* width: 100vw; */
}
.about-section .about-section-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.about-section .about-section-item #mobile{
    display: none;
}
.about-section .about-content {
  max-width: 28.125rem;
  position: absolute;
  top: 25%;
  left: 5%;
}

.about-content .about-content-title {
  color: #043d16;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.about-content .about-content-subtitle {
  color: #043d16;
  font-size: 40px;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.about-content p {
  width: 100%;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}


@media (orientation: portrait) {
  .about-section {
    padding: 38px 18px;
  }
  .about-section .about-section-item #web {
   display: none;
}
.about-section .about-section-item #mobile {
  display: block;
}
.about-section .about-content {
  bottom: 13vh;
  text-align: center;
  padding: 30px 0px;
  margin: 0 auto;
  top: 36px;
}
.about-content .about-content-title {
  font-size: 24px;
}
.about-content .about-content-subtitle {
  font-size: 24px;
}
}
