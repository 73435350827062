.banner-section {
  width: 100vw;
  height: 93.555vh;
}

#video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
}
.image-container {
  display: none;
}
.banner-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
#content {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

#content h1 {
  color: #fff;
  text-align: center;
  font-size: 86px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

#content p {
  max-width: 835px;
  color: #FFF;
  text-align: center;
  font-family: Karla;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 19px;
}
#content-mobile {
  display: none;
}

@media (orientation: portrait) {
  .banner-section {
    position: relative;
    top: -150px;
    width: 100%;
    height: 100%;
  }
  #video-container {
    display: none;
  }
  .image-container {
    display: block;
  }
  #content {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }
.banner-image{
  max-height: 450px; 
  width: 100%;
}
.banner-image img{
  height: 100%; 
  width: 100%;
}

  #content h1 {
    font-size: 26px;
  }

  #content p {
    font-size: 14px;
    margin-top: 19px;
  }
}
