.our-products {
  padding: 140px 0;
}
.our-products .products-header .produc-dec {
  max-width: 570px;
}
.our-products .products-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 140px; */
  margin-bottom: 56px;
}
.our-products .products-header .products-title {
  color: #043d16;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.our-products .products-header .products-title span {
  font-weight: 300;
}
.our-products .products-header p {
  max-width:  589px;
  color: #000;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  /* margin-top: 16px; */
}
.our-products .products-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.our-products .products-grid img {
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
}
.our-products .products-grid p {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}
.vertical {
  width: 2px;
  height: 150px;
  background-color: #000000;
  margin-top: 100px;
}
.our-products-swip{
  display: flex;
  align-items: center;
  /* grid-template-columns: 5.5fr 1fr 5.5fr; */
  justify-content: center;
  /* grid-template-columns: 5.5fr 1fr 5.5fr; */
}

.our-products-swip-content{
  max-width: 45%;
  margin: 0 auto;
}
.product-slider .swiper-button-next,
.product-slider .swiper-button-prev {
    width        : 3.25rem;
    height       : 3.25rem;
    border       : 1px solid #ACACAC;
    background-color: #043d16;
    border-radius: 50%;
}

.product-slider .swiper-button-next:hover,
.product-slider .swiper-button-prev:hover {
    border: 1px solid #043D16;
}

.product-slider .swiper-button-next:after {
    content            : '';
    width              : 18.9px;
    height             : 18.9px;
    background         : url('../../../../assets/icons/arrow-right2.svg') no-repeat 100%;
    background-position: center;
}

.product-slider .swiper-button-prev:after {
    content            : '';
    width              : 18.9px;
    height             : 18.9px;
    background         : url('../../../../assets/icons/arrow-left2.svg') no-repeat 100%;
    background-position: center;
}


.product-slider  .swiper-button-prev{
  top: 50%;
  left: 0%;
}
.product-slider  .swiper-button-next{
  top: 50%;
  left: 90%;
}
.product-slider .swiper-button-next, .product-slider .swiper-button-prev{
  width: 4rem;
  height: 4rem;
}



@media (orientation: portrait) {

  .our-products {
    padding: 70px 12px;
  }

  .our-products .products-header .products-title {
    font-size: 24px;
  }

  .our-products .products-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .our-products .products-header .produc-dec {
    max-width: 353px;
  }
  .our-products .products-header p {
    font-size: 12px;
  }
  .our-products .products-header {
    /* margin-bottom: 45px; */
    margin-bottom: 36px;
  }

  .our-products-swip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 100px;
}

.vertical {
 display: none;
}
.our-products-swip-content{
  max-width: 100%;
  margin: 0 auto;
}




.product-slider .swiper-button-next,
.product-slider .swiper-button-prev {
    width        : 3.25rem;
    height       : 3.25rem;
    border       : 1px solid #ACACAC;
    background-color: #043d16;
    border-radius: 50%;
    z-index: 110;
}

.product-slider  .swiper-button-prev{
  top: 50%;
  left: 0%;
}
.product-slider  .swiper-button-next{
  top: 50%;
  left: 87%;
}
.product-slider .swiper-button-next, .product-slider .swiper-button-prev{
  width: 2rem;
  height: 2rem;
}
.container {
  padding: 40px 0px
}


}
