.our-clients {
  padding: 90px 150px;
  margin: 0 auto;
}
.clients-image {
  max-width: 152px;
  max-height: 155px;
}
.our-clients img {
  /* max-width: 152px;
    max-height: 155px;  */
}

@media (orientation: portrait) {
    .our-clients {
        padding: 40px 30px;
        margin: 0 auto;
      }
  /* .our-client_card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } */
  .our-clients img {
    width: 100%;
    height: 100%;
    
  }
  .clients-image {
    width: 76px;
    height: 76px;
  }
}
