.footer {
  background-color: #043d16;
  padding: 120px 0 76px 0;
}
.footer-section {
  display: grid;
  grid-template-columns: 2fr 10fr;
}
.footer-section .footer-image {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */

  width: 226px;
  height: 235px;
  flex-shrink: 0;
}
.footer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footer-grid .left-section {
  text-align: left;
}
.footer-grid .left-section p {
  color: #fbd601 !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 105% */
}
.footer-grid .left-section span {
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; 

  text-align: left;
}

.footer-grid .left-section span,
.footer-grid .middle-section p {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.09px;
  margin-bottom: 1rem;
}
/* .footer-grid .left-sectiom p{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
} */
.footer-grid .rigth-section {
  color: #fff;
  display: flex;
  margin-bottom: 1rem;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.footer-grid .rigth-section .social-icon {
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.footer-grid .rigth-section .social-icon .social-icon-img {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  color: #043d16;
}
/* .footer-grid .rigth-section .social-icon .social-icon-img{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: #FFF;
  
} */

@media (orientation: portrait) {

  .footer {
    padding: 32px 0 42px 0;
  }
  .footer-section {
    display: grid;
    grid-template-columns: 1fr;
  }
   .footer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 38px;
    padding: 0 1rem;
    margin: 0 auto;
  }
  .footer-section .footer-image {
    width: 100%;
    height: 100%;
    padding: 3rem 0;
  }
  
}
