* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --gray-1: #e6e6e6;
  --gray-2: #cccccc;
  --gray-3: #b3b3b3;
  --gray-4: #999999;
  --gray-5: #808080;
  --gray-6: #666666;
  --gray-7: #4d4d4d;
  --gray-8: #333333;
  --gray-9: #1a1a1a;
  --black: #000000;

  --bright-red: #eb5757;
  --dark-red: #660101;
}

html {
  /* font-size      : 62.5%; */
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
}

svg,
img {
  vertical-align: middle;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea,
select {
  border: 0;
}
textarea {
  resize: none;
}
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
button:focus-visible {
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (orientation: portrait) {
  button {
    cursor: auto;
  }
  .section_title h2 {
    color: #dcdcdf;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    text-align: left;
    padding: 1rem 0;
  }
  .flex_section_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex_section_title p {
    color: #7d848f;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
  .arrow_icon svg {
    width: 24px;
    height: 24px;
  }
}
