.counter_section{
  background-color: #043d16;
}
.counter {
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 111px 0;
}
.counter .counter-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
}

.counter .counter-items .count-num p {
  color: #fff;
  /* font-size: 32px; */
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform:uppercase;
}
.counter .counter-items p {
  text-align: center;
  font-family: Karla;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 131.25% */
}

@media (orientation: portrait) {
  .counter {
    grid-template-columns: repeat(2, 1fr); 
    gap: 48px;
    padding: 50px 0;
  }
  .counter .counter-items:nth-child(3) {
    grid-column: 1 / span 2; 
    grid-row: 2; 
  }

  .counter .counter-items .count-num p {
    font-size: 20px ;
  }
  .counter .counter-items p{
    font-size: 12px;
  }


}
